import { Input, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { TableCellRendererProps } from "./types";

export const clientId =
  import.meta.env.VITE_CLIENT_ID ?? "5d7c1d57-b6fd-4f46-bd7c-c9589ebdfef1";
export const redirectUri =
  import.meta.env.VITE_REDIRECT_URI ?? "https://innovatex.ai-stg.ab-inbev.com";
export const fixed =
  import.meta.env.VITE_API_URL ?? "https://dev.innox.ab-inbev.com/api/";

export const categoryDistributionRSA = { BEER: 6.11, "BEYOND BEER": 6.15 };
export const categoryDistributionBR = {
  BEER: 4.9,
  "BEYOND BEER": 22.8,
  NAB: 21.8,
  "NON ALC BEER": 5.9,
};
export const generateRandomPercentage = (): string => {
  const randomValue = Math.floor(Math.random() * 101); // Generate a random number between 0 and 100
  return `${randomValue}%`;
};

export const innoExplore = [
  {
    country: "SAF",
    abi: [
      {
        type: "BUDWEISER",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CARLING BLACK LABEL",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "2%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "93%",
              },
              {
                type: "CAN",
                value: "6%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE DOUBLE MALT",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "8%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "67%",
              },
              {
                type: "CAN",
                value: "25%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LAGER",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "2%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "91%",
              },
              {
                type: "CAN",
                value: "7%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LITE",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "14%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "56%",
              },
              {
                type: "CAN",
                value: "30%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE MILK STOUT",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "2%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "89%",
              },
              {
                type: "CAN",
                value: "9%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CORONA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "94%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "6%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "7%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "82%",
              },
              {
                type: "CAN",
                value: "10%",
              },
              {
                type: "NRB",
                value: "1%",
              },
            ],
          },
        ],
      },
      {
        type: "HANSA PILSNER",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "3%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "81%",
              },
              {
                type: "CAN",
                value: "16%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "LION LAGER",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "83%",
              },
              {
                type: "CAN",
                value: "17%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "STELLA ARTOIS",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "33%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "17%",
              },
              {
                type: "NRB",
                value: "50%",
              },
            ],
          },
        ],
      },
    ],
    "heineken beverages": [
      {
        type: "AMSTEL",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "3%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "81%",
              },
              {
                type: "CAN",
                value: "12%",
              },
              {
                type: "NRB",
                value: "3%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "17%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "50%",
              },
              {
                type: "CAN",
                value: "18%",
              },
              {
                type: "NRB",
                value: "15%",
              },
            ],
          },
        ],
      },
      {
        type: "HEINEKEN",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "18%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "39%",
              },
              {
                type: "CAN",
                value: "16%",
              },
              {
                type: "NRB",
                value: "27%",
              },
            ],
          },
        ],
      },
      {
        type: "SOL",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "100%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "SOWETO GOLD",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "TAFEL",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "WINDHOEK",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "23%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "49%",
              },
              {
                type: "CAN",
                value: "29%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
    ],
    "total market": [
      {
        type: "CORE",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "2%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "91%",
              },
              {
                type: "CAN",
                value: "7%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CORE+",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "10%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "68%",
              },
              {
                type: "CAN",
                value: "21%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "9%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "78%",
              },
              {
                type: "CAN",
                value: "11%",
              },
              {
                type: "NRB",
                value: "3%",
              },
            ],
          },
        ],
      },
      {
        type: "HE",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "41%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "24%",
              },
              {
                type: "CAN",
                value: "13%",
              },
              {
                type: "NRB",
                value: "21%",
              },
            ],
          },
        ],
      },
      {
        type: "PREMIUM",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
    ],
  },
];
export const updatedExplorePer = [
  {
    country: "SAF",
    abi: [
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "9%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "70%",
              },
              {
                type: "CAN",
                value: "22%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "2%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "91%",
              },
              {
                type: "CAN",
                value: "7%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "77%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "9%",
              },
              {
                type: "NRB",
                value: "14%",
              },
            ],
          },
        ],
      },
      {
        type: "BUDWEISER",
        segment: "P / SP",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CARLING BLACK LABEL",
        segment: "CORE",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "2%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "93%",
              },
              {
                type: "CAN",
                value: "6%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE DOUBLE MALT",
        segment: "CORE+",
        "Brand Category": "Expansion",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "8%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "67%",
              },
              {
                type: "CAN",
                value: "25%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LAGER",
        segment: "CORE",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "2%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "91%",
              },
              {
                type: "CAN",
                value: "7%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LITE",
        segment: "CORE+",
        "Brand Category": "Sustain",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "14%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "56%",
              },
              {
                type: "CAN",
                value: "30%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE MILK STOUT",
        segment: "CORE+",
        "Brand Category": "Sustain",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "2%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "89%",
              },
              {
                type: "CAN",
                value: "9%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CORONA",
        segment: "P / SP",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "94%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "6%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },

      {
        type: "HANSA PILSNER",
        segment: "CORE",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "3%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "81%",
              },
              {
                type: "CAN",
                value: "16%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "LION LAGER",
        segment: "CORE",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "83%",
              },
              {
                type: "CAN",
                value: "17%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "STELLA ARTOIS",
        segment: "P / SP",
        "Brand Category": "Expansion",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "33%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "17%",
              },
              {
                type: "NRB",
                value: "50%",
              },
            ],
          },
        ],
      },
    ],
    "heineken beverages": [
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "15%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "63%",
              },
              {
                type: "CAN",
                value: "21%",
              },
              {
                type: "NRB",
                value: "1%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "100%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "20%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "38%",
              },
              {
                type: "CAN",
                value: "16%",
              },
              {
                type: "NRB",
                value: "26%",
              },
            ],
          },
        ],
      },
      {
        type: "AMSTEL",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "3%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "81%",
              },
              {
                type: "CAN",
                value: "12%",
              },
              {
                type: "NRB",
                value: "3%",
              },
            ],
          },
        ],
      },

      {
        type: "HEINEKEN",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "18%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "39%",
              },
              {
                type: "CAN",
                value: "16%",
              },
              {
                type: "NRB",
                value: "27%",
              },
            ],
          },
        ],
      },
      {
        type: "SOL",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "100%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "SOWETO GOLD",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "TAFEL",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "WINDHOEK",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "23%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "49%",
              },
              {
                type: "CAN",
                value: "29%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
    ],
    "total market": [
      {
        type: "CORE",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "2%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "91%",
              },
              {
                type: "CAN",
                value: "7%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "CORE+",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "10%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "68%",
              },
              {
                type: "CAN",
                value: "21%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "9%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "78%",
              },
              {
                type: "CAN",
                value: "11%",
              },
              {
                type: "NRB",
                value: "3%",
              },
            ],
          },
        ],
      },
      {
        type: "P / SP",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "0%",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0%",
              },
              {
                type: "CAN",
                value: "0%",
              },
              {
                type: "NRB",
                value: "41%",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "24%",
              },
              {
                type: "CAN",
                value: "13%",
              },
              {
                type: "NRB",
                value: "21%",
              },
            ],
          },
        ],
      },
    ],
  },
];
const addPercentageSign = (value: string): string => {
  // Check if the value already contains a '%' sign
  if (value.includes("%")) {
    return value; // Return the value as is
  }
  // Append a '%' sign to the value
  return `${value}%`;
};

// Recursive function to update the value fields in the array
const updateValuesWithPercentage = (data: any): any => {
  if (Array.isArray(data)) {
    return data.map(updateValuesWithPercentage);
  } else if (typeof data === "object" && data !== null) {
    const updatedObject: any = {};
    for (const key in data) {
      if (key === "value" && typeof data[key] === "string") {
        updatedObject[key] = addPercentageSign(data[key]);
      } else {
        updatedObject[key] = updateValuesWithPercentage(data[key]);
      }
    }
    return updatedObject;
  }
  return data;
};
export const updatedExplorePerWithPercentage = updatedExplorePer;

export const updatedExploreAbs = [
  {
    country: "SAF",
    abi: [
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "6.11",
              },
              {
                type: "NRB",
                value: "318.48",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "2,566.18",
              },
              {
                type: "CAN",
                value: "784.89",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "7.99",
              },
              {
                type: "NRB",
                value: "256.87",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "12,570.52",
              },
              {
                type: "CAN",
                value: "912.74",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0.02",
              },
              {
                type: "NRB",
                value: "734.74",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "79.02",
              },
              {
                type: "NRB",
                value: "121.36",
              },
            ],
          },
        ],
      },
      {
        type: "BUDWEISER",
        segment: "P / SP",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "2.85",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CARLING BLACK LABEL",
        segment: "CORE",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "4.85",
              },
              {
                type: "NRB",
                value: "156.29",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "9,031.03",
              },
              {
                type: "CAN",
                value: "558.82",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE DOUBLE MALT",
        segment: "CORE+",
        "Brand Category": "Expansion",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "18.16",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "178.31",
              },
              {
                type: "CAN",
                value: "57.09",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LAGER",
        segment: "CORE",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "3.17",
              },
              {
                type: "NRB",
                value: "70.96",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "2,659.57",
              },
              {
                type: "CAN",
                value: "203.18",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LITE",
        segment: "CORE+",
        "Brand Category": "Sustain",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "6.11",
              },
              {
                type: "NRB",
                value: "274.53",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1,138.47",
              },
              {
                type: "CAN",
                value: "602.57",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE MILK STOUT",
        segment: "CORE+",
        "Brand Category": "Sustain",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "25.78",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1,249.4",
              },
              {
                type: "CAN",
                value: "125.23",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CORONA",
        segment: "P / SP",
        "Brand Category": "Mega",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "642.58",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "42.3",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "HANSA PILSNER",
        segment: "CORE",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "29.63",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "561.99",
              },
              {
                type: "CAN",
                value: "96.68",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "LION LAGER",
        segment: "CORE",
        "Brand Category": "Tail",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "317.94",
              },
              {
                type: "CAN",
                value: "54.06",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "STELLA ARTOIS",
        segment: "P / SP",
        "Brand Category": "Expansion",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "89.31",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "36.72",
              },
              {
                type: "NRB",
                value: "121.36",
              },
            ],
          },
        ],
      },
    ],
    "heineken beverages": [
      {
        type: "Default",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "1.51",
              },
              {
                type: "NRB",
                value: "205.64",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "910.28",
              },
              {
                type: "CAN",
                value: "297.43",
              },
              {
                type: "NRB",
                value: "15.61",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "9.36",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "10.77",
              },
              {
                type: "CAN",
                value: "0.03",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "1.84",
              },
              {
                type: "NRB",
                value: "327.6",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "625.19",
              },
              {
                type: "CAN",
                value: "260.2",
              },
              {
                type: "NRB",
                value: "426.64",
              },
            ],
          },
        ],
      },
      {
        type: "AMSTEL",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "1.31",
              },
              {
                type: "NRB",
                value: "29.78",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "549.26",
              },
              {
                type: "CAN",
                value: "75.73",
              },
              {
                type: "NRB",
                value: "15.61",
              },
            ],
          },
        ],
      },

      {
        type: "HEINEKEN",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "1.84",
              },
              {
                type: "NRB",
                value: "273.03",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "625.19",
              },
              {
                type: "CAN",
                value: "260.16",
              },
              {
                type: "NRB",
                value: "426.53",
              },
            ],
          },
        ],
      },
      {
        type: "SOL",
        segment: "P / SP",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "54.58",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0.1",
              },
            ],
          },
        ],
      },
      {
        type: "SOWETO GOLD",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "10.52",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "TAFEL",
        segment: "CORE",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "9.36",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0.25",
              },
              {
                type: "CAN",
                value: "0.03",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "WINDHOEK",
        segment: "CORE+",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0.2",
              },
              {
                type: "NRB",
                value: "175.87",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "361.02",
              },
              {
                type: "CAN",
                value: "221.74",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
    ],
    "total market": [
      {
        type: "Default",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "17.47",
              },
              {
                type: "NRB",
                value: "1,852.69",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "16,682.94",
              },
              {
                type: "CAN",
                value: "2,334.32",
              },
              {
                type: "NRB",
                value: "563.61",
              },
            ],
          },
        ],
      },
      {
        type: "CORE",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "8",
              },
              {
                type: "NRB",
                value: "266.23",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "12,581.29",
              },
              {
                type: "CAN",
                value: "912.77",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CORE+",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "7.62",
              },
              {
                type: "NRB",
                value: "524.12",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "3,476.46",
              },
              {
                type: "CAN",
                value: "1,082.32",
              },
              {
                type: "NRB",
                value: "15.61",
              },
            ],
          },
        ],
      },

      {
        type: "P / SP",
        segment: "",
        "Brand Category": "",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "1.86",
              },
              {
                type: "NRB",
                value: "1,062.34",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "625.19",
              },
              {
                type: "CAN",
                value: "339.23",
              },
              {
                type: "NRB",
                value: "548",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const innoExploreAbs = [
  {
    country: "SAF",
    abi: [
      {
        type: "BUDWEISER",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "2",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CARLING BLACK LABEL",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "4",
              },
              {
                type: "NRB",
                value: "156",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "9,031",
              },
              {
                type: "CAN",
                value: "558",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE DOUBLE MALT",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "18",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "178",
              },
              {
                type: "CAN",
                value: "57",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LAGER",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "3",
              },
              {
                type: "NRB",
                value: "70",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "2,659",
              },
              {
                type: "CAN",
                value: "203",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE LITE",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "6",
              },
              {
                type: "NRB",
                value: "274",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1,138",
              },
              {
                type: "CAN",
                value: "602",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CASTLE MILK STOUT",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "25",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1,249",
              },
              {
                type: "CAN",
                value: "125",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CORONA",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "642",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "42",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "14",
              },
              {
                type: "NRB",
                value: "1,310",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "15,136",
              },
              {
                type: "CAN",
                value: "1,776",
              },
              {
                type: "NRB",
                value: "121",
              },
            ],
          },
        ],
      },
      {
        type: "HANSA PILSNER",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "29",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "561",
              },
              {
                type: "CAN",
                value: "96",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "LION LAGER",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "317",
              },
              {
                type: "CAN",
                value: "54",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "STELLA ARTOIS",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "89",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "36",
              },
              {
                type: "NRB",
                value: "121",
              },
            ],
          },
        ],
      },
    ],
    "heineken beverages": [
      {
        type: "AMSTEL",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "1",
              },
              {
                type: "NRB",
                value: "29",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "549",
              },
              {
                type: "CAN",
                value: "75",
              },
              {
                type: "NRB",
                value: "15",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "3",
              },
              {
                type: "NRB",
                value: "542",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "1,546",
              },
              {
                type: "CAN",
                value: "557",
              },
              {
                type: "NRB",
                value: "442",
              },
            ],
          },
        ],
      },
      {
        type: "HEINEKEN",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "1",
              },
              {
                type: "NRB",
                value: "273",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "625",
              },
              {
                type: "CAN",
                value: "260",
              },
              {
                type: "NRB",
                value: "426",
              },
            ],
          },
        ],
      },
      {
        type: "SOL",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "54",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "SOWETO GOLD",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "10",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "TAFEL",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "9",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "WINDHOEK",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "175",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "361",
              },
              {
                type: "CAN",
                value: "221",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
    ],
    "total market": [
      {
        type: "CORE",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "8",
              },
              {
                type: "NRB",
                value: "266",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "12,581",
              },
              {
                type: "CAN",
                value: "912",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
      {
        type: "CORE+",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "7",
              },
              {
                type: "NRB",
                value: "524",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "3,476",
              },
              {
                type: "CAN",
                value: "1,082",
              },
              {
                type: "NRB",
                value: "15",
              },
            ],
          },
        ],
      },
      {
        type: "Default",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "17",
              },
              {
                type: "NRB",
                value: "1,852",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "16,682",
              },
              {
                type: "CAN",
                value: "2,334",
              },
              {
                type: "NRB",
                value: "563",
              },
            ],
          },
        ],
      },
      {
        type: "HE",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "1",
              },
              {
                type: "NRB",
                value: "1,062",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "625",
              },
              {
                type: "CAN",
                value: "339",
              },
              {
                type: "NRB",
                value: "548",
              },
            ],
          },
        ],
      },
      {
        type: "PREMIUM",
        items: [
          {
            entry: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            frequency: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
          {
            intensity: [
              {
                type: "RB",
                value: "0",
              },
              {
                type: "CAN",
                value: "0",
              },
              {
                type: "NRB",
                value: "0",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const pptData = [
  {
    Zone: "Africa",
    Country: "South Africa",
    Financials_source: "UMAMI",
    Refresh_year: 2024,
    Refresh_month: "August",
  },
  {
    Zone: "SAZ",
    Country: "Brazil",
    Financials_source: "CUBO",
    Refresh_year: 2024,
    Refresh_month: "August",
  },
];

export const filterByCountry = (country: string) =>
  pptData.filter((item) => item.Country === country)[0];

export const chakraStyles = (
  customWidth: string,
  customFont: string,
  customAlign: string
) => ({
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    width: customWidth,
    fontSize: customFont,
    fontWeight: "600",
    textAlign: customAlign,
    color: "black",
    bg: "white",
    padding: "0 16px 0",
    backgroundColor: "white !important",
    // border: "none",
    borderColor: state.isFocused
      ? "primaryBlack !important"
      : "border !important",
    borderRadius: "8px",
    boxShadow: "none !important",
  }),
  placeholder: (baseStyles: any) => ({
    ...baseStyles,
    color: "#1E1E1ECC",
  }),
  valueContainer: (baseStyles: any) => ({
    ...baseStyles,
    px: "0",
  }),
  multiValue: (baseStyles: any) => ({
    ...baseStyles,
    bg: "border",
    fontSize: "12px",
  }),
  dropdownIndicator: (baseStyles: any) => ({
    ...baseStyles,
    px: "200px",
    bg: "green",
    cursor: "pointer",
  }),
  option: (baseStyles: any, state: any) => ({
    ...baseStyles,
    color: "textBlack !important",
    fontSize: "12px",
    background: state.isFocused ? "border !important" : "none",
  }),
});
export const filterInfo: { [key: string]: string } = {
  // Brand: "Brand Name",
  // "Beer Type": "Type of beer",
  // "Pack Size": "Volume size of the pack. i.e. 330 ml",
  // "Price Segment": "SKU falling in the four core price segments",
  // ABV: "Alcohol by volume %",
  // "Pack/Return Type": "Container type of the pack",
  // "Launch Period": "Which quarter innovation is going to be launched?",
  // Districts: "Targeted district for the innovation to be launched",
  // "POC Segment": "Segments where innovation is planning to be launched",
  "Price per HL": "Expected NR per HL",
  "MACO per HL": "Unplugged MACO/HL",
  Distribution: "% of POC where the product will be available",
};

export const RegionalColumn = [
  {
    header: "Districts",
    accessorKey: "R_Districts",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "POC Segments",
    accessorKey: "R_POC_Segments",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "NR per HL (USD)",
    accessorKey: "R_Price_per_HL",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "Distribution %",
    accessorKey: "R_Distribution",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
];
export const BrazilRegionalColumn = [
  {
    header: "Regions",
    accessorKey: "R_Regions",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "Channels",
    accessorKey: "R_Channels",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "NR per HL (USD)",
    accessorKey: "R_Price_per_HL",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
  {
    header: "Distribution %",
    accessorKey: "R_Distribution",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace="normal"> {props.getValue()}</Text>
    ),
  },
];

export const TableCellRenderer: React.FC<TableCellRendererProps> = React.memo(
  ({ cellProps, onDataChange }) => {
    const { metric } = cellProps.row.original;
    const value = cellProps.getValue() as string;
    const year = cellProps.column.id;

    const handleBlur = (yea3r: string, value3: string) => {
      onDataChange(yea3r, value3); // Call the parent callback to pass data up
    };

    if (metric === "Value") {
      return (
        <Input
          fontSize="11px"
          border="1px solid #a7a4a4"
          fontWeight="700"
          fontFamily="Avantt"
          defaultValue={value}
          onChange={(e) => handleBlur(year, e.target.value)} // Update the value on blur
        />
      );
    }

    return <Text>{value}</Text>;
  }
);
