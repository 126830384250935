import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
} from "@chakra-ui/react";

import InnoExploreGrid from "./InnoExploreTable";
import NigeriaFlag from "innovate/assets/flags/NigeriaFlag.svg";
import SAFLAG from "innovate/assets/flags/RSAFLAG.svg";
import abiBrand from "innovate/assets/images/abiBrand.svg";
import beerIcon from "innovate/assets/images/globalBeer.svg";
import bottleIcon from "innovate/assets/images/globalBottle.svg";
import heineken from "innovate/assets/images/heineken.svg";
import React, { useEffect, useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

interface Entry {
  type: string;
  value: string;
}

interface Item {
  entry?: Entry[];
  frequency?: Entry[];
  intensity?: Entry[];
}

interface Category {
  type?: string;
  items?: Item[];
  dataKey?: string;
  segment?: string;
  "Brand Category"?: string;
}

interface CountryData {
  country: string;
  "total market": Category[];
}

interface ExploreCountryProps {
  data: CountryData;
  isSwitchOn: boolean;
}
interface LabelsGridProps {
  label: string;
}

const getBackgroundColor = (label: string) => {
  switch (label) {
    case "Entry":
      return "#FEFBE0";
    case "Frequency":
      return "#FFF8BC";
    case "Intensity":
      return "#F4E776";
    default:
      return "#FFFFFF"; // Default color if none of the cases match
  }
};

const getLabelContent = (label: string) => {
  switch (label) {
    case "Entry":
      return <Text>{"<300ml"}</Text>;
    case "Frequency":
      return (
        <>
          {/* <Text as="span"></Text> */}
          <Text as="span">CANS(300-400ml), Bottle(300-600ml)</Text>
        </>
      );
    case "Intensity":
      return (
        <>
          {/* <Text as="span"></Text> */}
          <Text as="span">CANS(&gt;400ml), Bottle(&gt;600ml)</Text>
        </>
      );
    default:
      return "";
  }
};

const LabelsGrid: React.FC<LabelsGridProps> = ({ label }) => (
  <Grid templateColumns="repeat(3, 1fr)" gap={4} mb={2}>
    {["RB", "CAN", "NRB"].map((type) => (
      <GridItem key={type}>
        <Center
          flexDirection="row"
          bg={getBackgroundColor(label)}
          p={2}
          h={10}
          gap={2}
          borderRadius="md"
        >
          <Image src={type === "CAN" ? beerIcon : bottleIcon} alt="icon" />
          <Text variant="custom">{type}</Text>
        </Center>
      </GridItem>
    ))}
  </Grid>
);

const filterDefaultValues = (data: CountryData): CountryData => {
  const sortedData = Array.from(data["total market"]).sort((a, b) => {
    if (a.type === "Default") return -1;
    if (b.type === "Default") return 1;
    return 0;
  });
  return {
    country: data.country,
    "total market": sortedData,
  };
};

const ExploreCountry: React.FC<ExploreCountryProps> = ({
  data,
  isSwitchOn,
}) => {
  const [filteredData, setFilteredData] = useState<CountryData | null>(null);

  const [expandedKeys, setExpandedKeys] = useState<Set<string>>(new Set());

  useEffect(() => {
    setExpandedKeys(new Set());
  }, [isSwitchOn]);

  const moveAbiToSecondPosition = (items: Category[]) => {
    // Find the index of the object with "dataKey": "abi"
    const abiIndex = items.findIndex((item) => item.dataKey === "abi");

    if (abiIndex !== -1) {
      // Remove the object from its current position
      const [abiItem] = items.splice(abiIndex, 1);

      // Insert the object at the second position
      if (abiItem) {
        items.splice(1, 0, abiItem);
      }
    }

    return items;
  };

  useEffect(() => {
    const defaultData = filterDefaultValues(data);
    setFilteredData(defaultData);
  }, [data]);

  const handleSubCategoryClick = (key: string, masterKey: string) => {
    const doesKeyExist =
      filteredData?.["total market"]?.some(
        (cat) =>
          cat.segment === key &&
          cat.type !== "Default" &&
          cat.dataKey === masterKey
      ) ?? false;

    if (doesKeyExist) {
      // Remove all entries with the same segment
      setFilteredData((prevData) => {
        if (!prevData) {
          return prevData; // Return early if prevData is null
        }

        const updatedData = { ...prevData };

        updatedData["total market"] = prevData["total market"].filter(
          (cat) =>
            cat.segment !== key ||
            (cat.type === "Default" && cat.segment === key)
        );

        return updatedData;
      });
      return;
    }

    const matchingItems: {
      dataKey: string;
      segment: string;
      type: string;
      item: Category;
    }[] = []; // Array to store matching items with dataKey

    Object.entries(data).forEach(([dataKey, dataValue]) => {
      if (Array.isArray(dataValue)) {
        dataValue.forEach((item) => {
          if (
            item.segment === key &&
            item.type !== "Default" &&
            dataKey === masterKey
          ) {
            const newItem = { ...item, dataKey }; // Append dataKey inside item
            matchingItems.push(newItem); // Store the matching item with dataKey
          }
        });
      }
    });

    // Update filteredData with matching items

    setFilteredData((prevData) => {
      if (!prevData) {
        return prevData; // Return early if prevData is null
      }
      const updatedData = { ...prevData };
      const totalMarket = updatedData["total market"] || [];

      // Ensure uniqueness in matchingItems
      const uniqueItems = matchingItems.filter(
        (item) =>
          !totalMarket.some(
            (marketItem) =>
              marketItem.dataKey === item.dataKey &&
              marketItem.segment === item.segment &&
              marketItem.type === item.type
          )
      );

      const keyIndex = totalMarket.findIndex(
        (category) =>
          category.dataKey === masterKey &&
          category.segment === key &&
          category.type === "Default"
      );

      if (keyIndex !== -1) {
        // Insert unique items at the correct position
        totalMarket.splice(keyIndex + 1, 0, ...uniqueItems);
      } else {
        // Add unique items to the end
        totalMarket.push(...uniqueItems);
      }

      updatedData["total market"] = totalMarket;
      return updatedData;
    });
  };
  const handlesegmentClick = (key: string) => {
    console.log("inside segment");

    const doesKeyExist =
      filteredData?.["total market"]?.some((cat) => cat.segment === key) ??
      false;

    if (doesKeyExist) {
      // Remove all entries with the same segment
      setFilteredData((prevData) => {
        if (!prevData) {
          return prevData; // Return early if prevData is null
        }
        const updatedData = { ...prevData };
        updatedData["total market"] = prevData["total market"].filter(
          (cat) => cat.segment !== key,
        );
        return updatedData;
      });
      return;
    }

    const matchingItems: { dataKey: string; item: Category[] }[] = []; // Array to store matching items with dataKey

    Object.entries(data).forEach(([dataKey, dataValue]) => {
      if (Array.isArray(dataValue)) {
        dataValue.forEach((item) => {
          if (item.segment === key && item.type === "Default") {
            const newItem = { ...item, dataKey }; // Append dataKey inside item
            matchingItems.push(newItem); // Store the matching item with dataKey
          }
        });
      }
    });
    // Update filteredData with matching items
    setFilteredData((prevData) => {
      if (!prevData) {
        return prevData; // Return early if prevData is null
      }
      const updatedData = { ...prevData };
      const totalMarket = updatedData["total market"] || [];

      // Check if matching items already exist to prevent duplicates
      const existingItems = totalMarket.some(
        (cat) => cat.segment === key && cat.type === "Default"
      );

      if (!existingItems) {
        const keyIndex = totalMarket.findIndex(
          (category) => category.type === key
        );
        const updatedMatchingItems = moveAbiToSecondPosition(matchingItems);

        if (keyIndex !== -1) {
          // Insert matching items just after the key
          totalMarket.splice(keyIndex + 1, 0, ...updatedMatchingItems);
        } else {
          // If key is not found, append matching items at the end
          totalMarket.push(...updatedMatchingItems);
        }

        updatedData["total market"] = totalMarket;
      }

      return updatedData;
    });
  };

  const handleOpenCat = (category: Category, id: string) => {
    setExpandedKeys((prevKeys) => {
      const newKeys = new Set(prevKeys);
      if (newKeys.has(id)) {
        newKeys.delete(id);
      } else {
        newKeys.add(id);
      }
      return newKeys;
    });
    const { type } = category;
    const { segment } = category;

    if (type !== "Default" && !segment) {
      handlesegmentClick(type);
    }
    if (segment && segment !== "") {
      const masterKey = category?.dataKey ?? "";
      if (masterKey && masterKey !== "") {
        if (type !== "Default") return;
        handleSubCategoryClick(segment, masterKey);
      }
    }
    // console.log("key", category, filteredData);
  };

  const getBackgroundColorCat = (category: {
    type: string;
    segment?: string;
  }): string => {
    if (category.type === "Default" && category.segment === "CORE") {
      return "#CCEBFB";
    }
    if (category.type === "Default" && category.segment === "CORE+") {
      return "#E5CCFF";
    }
    if (category.type === "Default" && category.segment === "P / SP") {
      return "#ffc9d6";
    }
    if (category.type === "CORE" || category.segment === "CORE") {
      return "#E6F5FD";
    }
    if (category.type === "CORE+" || category.segment === "CORE+") {
      return "#F3EBFC";
    }
    if (category.type === "P / SP" || category.segment === "P / SP") {
      return "#FDE6EB";
    }
    // Add more conditions as needed
    if (category.type === "Default") {
      return "#DCDCDC"; // Default color if colors array is empty
    }
    return "#F2F2F2";
  };

  const getCategoryContent = (category: Category) => {
    if (category.type === "Default" && category.dataKey === "abi") {
      return <Image src={abiBrand} alt="abiBrand" w="70px" />;
    } else if (
      category.type === "Default" &&
      category.dataKey === "heineken beverages"
    ) {
      return <Image src={heineken} alt="heineken" w="70px" />;
    } else {
      return category.type;
    }
  };

  const getRandomColor = (brandCat: string) => {
    // Check if the brandCat already has an assigned color
    if (brandCat === "Mega") {
      return "#F1D513";
    }
    if (brandCat === "Tail") {
      return "#8DD262";
    }
    if (brandCat === "Expansion") {
      return "#F1D513";
    }
    if (brandCat === "Sustain") {
      return "#9ED0F5";
    }
    return "#8DD262";
  };

  if (!filteredData) return null;
  return (
    <Box p={4} bg="white" boxShadow="md" borderRadius="md" mb={10}>
      <Flex alignItems="flex-start" mt={2} gap={4} mb={2}>
        <Flex alignItems="flex-start" direction="column" width="15%">
          <Flex className="globalTableFilter" mb={1.5} gap="2%" p="7%" mb={2}>
            <Image
              src={filteredData.country === "SAF" ? SAFLAG : NigeriaFlag}
              alt="South Africa"
            />
            <Text variant="custom" ml={1}>
              {filteredData.country}
            </Text>
          </Flex>
          <Flex
            mb={2}
            h="34px"
            // border="1px solid #00000014"
            padding="8px"
            width="100%"
            borderRadius="md"
            fontWeight="600"
            justifyContent="space-between"
            alignItems="center"
            cursor="pointer"
            height="35px"
            bg="#DCDCDC"
            // onClick={() => handleClick("total market")}
          >
            <Text variant="custom" ml={1} isTruncated>
              Total Market
            </Text>
            {/* <FiChevronDown /> */}
            <FiChevronUp />
          </Flex>

          {filteredData["total market"]
            .filter(
              (category) => category.dataKey || category.type !== "Default"
            )
            .map((category, index) => (
              <Flex
                key={`total_market-${index}`}
                // border="1px solid #00000014"
                mb={2}
                h="34px"
                id={`total_market-${index}`}
                // border="1px solid #00000014"
                padding="8px"
                width="100%"
                borderRadius="md"
                fontWeight="600"
                justifyContent="space-between"
                alignItems="center"
                cursor="pointer"
                height="35px"
                bg={getBackgroundColorCat(category)}
                onClick={() => handleOpenCat(category, `total_market-${index}`)} // Pass the id here
              >
                <Flex dir="row">
                  {category["Brand Category"] !== "" && (
                    <Text
                      variant="custom"
                      bg={getRandomColor(category["Brand Category"])}
                      p={1}
                      mr={1}
                      borderRadius="50%"
                      width="16px"
                      height="16px"
                      display="flex"
                      fontSize="10px"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {category["Brand Category"]?.charAt(0)}
                    </Text>
                  )}
                  <Text variant="custom" ml={1} isTruncated>
                    {getCategoryContent(category)}
                  </Text>
                </Flex>
                {(category.type === "Default" || category.segment === "") &&
                  (expandedKeys.has(`total_market-${index}`) ? (
                    <FiChevronUp />
                  ) : (
                    <FiChevronDown />
                  ))}
              </Flex>
            ))}
        </Flex>
        <Flex direction="column" width="85%">
          {Object.entries(filteredData).map(([key, value]) => {
            if (!Array.isArray(value)) return null;
            return (
              <Box key={key}>
                {key === "total market" && (
                  <Flex gap={6} justifyContent="space-between" flexWrap="wrap">
                    {["Entry", "Frequency", "Intensity"].map((label, idx) => (
                      <Box
                        key={idx}
                        flex="1"
                        maxWidth="33%"
                        p={0}
                        minWidth="200px"
                      >
                        <Center
                          mb={2}
                          border="1px solid #D8D8D8"
                          p={1}
                          borderRadius="md"
                          bg={getBackgroundColor(label)}
                        >
                          <Text variant="custom">{label}</Text>
                          <Box ml={2} px={1} bg="yellow.400" borderRadius="md">
                            <Text
                              variant="custom"
                              className="explorePack"
                              fontSize="10px"
                            >
                              {getLabelContent(label)}
                            </Text>
                          </Box>
                        </Center>
                        <LabelsGrid label={label} />
                      </Box>
                    ))}
                  </Flex>
                )}

                {value.map((category, index) => (
                  <Box key={index}>
                    {Object.entries(category).map(
                      ([categoryKey, categoryValue]) => {
                        if (!Array.isArray(categoryValue)) return null;
                        return (
                          <Box key={categoryKey} mb={2}>
                            <Grid
                              templateColumns="repeat(auto-fit, minmax(200px, 1fr))"
                              gap={6}
                            >
                              {categoryValue.map((entry, idx) => (
                                <GridItem key={idx} p={0}>
                                  <InnoExploreGrid
                                    data={entry}
                                    color={getBackgroundColorCat(category)}
                                  />
                                </GridItem>
                              ))}
                            </Grid>
                          </Box>
                        );
                      }
                    )}
                  </Box>
                ))}
              </Box>
            );
          })}
        </Flex>
      </Flex>
    </Box>
  );
};

export default ExploreCountry;
